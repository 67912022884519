<template>
  <div class="company-settings">
    <div class="page-header">
      <h1>{{ $t('Settings') }} > {{ $t('Company Settings') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Company Settings') }}</h2>
      </div>
      <div class="form-content">
        <div class="tips-text text-muted">{{ $t('These content will display on invoice and quotation.') }}</div>
        <el-form ref="form" :model="form">
          <el-form-item :label="$t('Company Name')" style="max-width: 80%">
            <el-input v-model="form.companyName"></el-input>
          </el-form-item>
          <el-form-item  :label="$t('Company Address')" style="max-width: 80%">
            <el-input v-model="form.companyAddress"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Company Phone')" style="max-width: 80%">
            <el-input v-model="form.companyPhone"></el-input>
          </el-form-item>
          <el-form-item  :label="$t('Company Email')" style="max-width: 80%">
            <el-input v-model="form.companyEmail"></el-input>
          </el-form-item>
        </el-form>
        <div class="update-button-wrapper">
          <el-button type="primary" @click="updateSettings">{{ $t('Update') }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'CompanySettings',
  data(){
    const form = {
      companyName: '',
      companyAddress: '',
      companyPhone: '',
      companyEmail: '',
    };
    return {
      form,
    }
  },
  mounted(){
    this.loadSettings();
  },
  methods: {
    async loadSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        let companyName = await Common.loadConfig(this.apiUrl, 'company_name', loginInfo);
        companyName = companyName['config_value'];

        let companyAddress = await Common.loadConfig(this.apiUrl, 'company_address', loginInfo);
        companyAddress = companyAddress['config_value'];

        let companyPhone = await Common.loadConfig(this.apiUrl, 'company_phone', loginInfo);
        companyPhone = companyPhone['config_value'];

        let companyEmail = await Common.loadConfig(this.apiUrl, 'company_email', loginInfo);
        companyEmail = companyEmail['config_value'];

        this.form.companyName = companyName;
        this.form.companyAddress = companyAddress;
        this.form.companyPhone = companyPhone;
        this.form.companyEmail = companyEmail;
      }catch(err){
        console.log(err);
      }
    },
    async updateSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        const companyName = this.form.companyName;
        const companyAddress = this.form.companyAddress;
        const companyPhone = this.form.companyPhone;
        const companyEmail = this.form.companyEmail;

        await Common.updateConfig(this.apiUrl, 'company_name', companyName, loginInfo);
        await Common.updateConfig(this.apiUrl, 'company_address', companyAddress, loginInfo);
        await Common.updateConfig(this.apiUrl, 'company_phone', companyPhone, loginInfo);
        await Common.updateConfig(this.apiUrl, 'company_email', companyEmail, loginInfo);

        this.$message({
          message: this.$t('The setting had been updated.'),
          type: 'success'
        });

      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.company-settings{
  .form-content{
    padding-left: 20px;
    .tips-text{
      margin-bottom: 20px;
    }
    .update-button-wrapper{
      text-align: right;
    }
  }
}
</style>
